// Rest API EndPoints
export const restAPIEndPoints = {
  // Authentication APIs
  // Function: Create a new user, Type: POST
  createUser: {
    url: "/admin/auth/user/add/",
    method: "POST",
  },
  // Function: User login, Type: POST
  login: { url: "/auth/login", method: "POST", isPublic: true },

  // Organization APIs
  // Function: Fetch organization contacts, Type: GET
  fetchOrgContacts: {
    url: "/core/organization/contacts/",
    method: "GET",
  },
  // Function: add organization contacts, Type: POST
  addOrgContacts: {
    url: "/core/organization/contacts/",
    method: "POST",
  },
  // Function: Fetch vendor contacts, Type: GET
  fetchOrgVendors: {
    url: "/core/organizationvendors/",
    method: "GET",
  },
  // Function: add contact to organisation , Type: POST
  addContactToOrg: {
    url: "/core/organization/",
    method: "POST",
  },
  // Function: add vendor , Type: POST
  addVendor: {
    url: "/core/organizationvendors/",
    method: "POST",
  },
  // Function: Fetch organization manpower, Type: GET
  fetchOrgManpower: {
    url: "/manpower/organizationmanpower/",
    method: "GET",
  },
  // Function: Fetch manpower items, Type: GET
  fetchManpowerItems: {
    url: "/manpower/items/",
    method: "GET",
  },

  // Function: add manpower items, Type: POST
  addManpowerItems: {
    url: "/manpower/items/",
    method: "POST",
  },

  // Function: add manpower items, Type: PUT
  updateManpowerItem: {
    url: "/manpower/items/?manpower_item_id={id}",
    method: "PUT",
  },
  // Function: delete manpower items, Type: DELETE
  deleteManpowerItem: {
    url: "/manpower/items/?manpower_item_id={id}",
    method: "DELETE",
  },
  // Function: Fetch project manpower, Type: GET
  fetchProjectManpower: {
    url: "/manpower/projectmanpower/?project_id={id}",
    method: "GET",
  },

  // Function: addManpowerToProject, Type: POST
  addManpowerToProject: {
    url: "/manpower/projectmanpower/?project_id={id}",
    method: "POST",
  },

  // Function: addAssetsToManpowerInProject, Type: POST
  addAssetsToManpowerInProject: {
    url: "/manpower/projectmanpowerassets/?project_manpower_id={id}",
    method: "POST",
  },

  // Function: Fetch auth groups, Type: GET
  fetchAuthGroups: {
    url: "/auth/groups/",
    method: "GET",
  },
  // Customer APIs
  // Function: Get all clients, Type: GET
  getAllClients: {
    url: "/customers/clients/",
    method: "GET",
  },
  // Function: add client, Type: GET
  addClient: {
    url: "/customers/client/",
    method: "POST",
  },
  // Function: update client, Type: PUT
  updateClient: {
    url: "/customers/client/?client_id={id}",
    method: "PUT",
  },

  // Function: update address, Type: PUT
  updateCoreAddress: {
    url: "/core/address/?address_id={id}",
    method: "PUT",
  },

  // Function: Get client by ID, Type: GET
  getClientById: {
    url: "/customers/client/?client_id={id}",
    method: "GET",
  },
  // Function: Get all projects, Type: GET
  getAllProjects: {
    url: "/customers/projects/",
    method: "GET",
  },

  // Function: Get projects based on user, Type: GET
  getUserProjects: {
    url: "/customers/userprojects/?organization_contact_id={id}",
    method: "GET",
  },

  // Function: Get project details by ID, Type: GET
  getProjectById: {
    url: "/customers/project/?project_id={id}",
    method: "GET",
  },

  // Function: Add Project, Type: POST
  addProject: {
    url: "/customers/project/",
    method: "POST",
  },
  // Function: Get project stages, Type: GET
  getProjectStages: {
    url: "/customers/projectstages/",
    method: "GET",
  },
  // Function: update project , Type: PUT
  updateProject: {
    url: "/customers/project/?project_id={id}",
    method: "PUT",
  },
  addProjectStage: {
    url: "/customers/projectstages/",
    method: "POST",
  },
  // Files APIs
  // Function: Fetch project files, Type: GET
  fetchProjectFiles: {
    url: "/customers/projectassets/?project_id={id}",
    method: "GET",
  },
  // Function: delete project file, Type: POST
  deleteProjectFile: {
    url: "/customers/projectassets/?project_asset_id={id}",
    method: "PUT",
  },
  // Moodboards APIs
  // Function: Fetch project boards, Type: GET
  fetchProjectBoards: {
    url: "/moodboards/projectboards/?project_id={id}",
    method: "GET",
  },
  // Function: add project board, Type: POST
  createProjectBoard: {
    url: "/moodboards/projectboards/?project_id={id}",
    method: "POST",
  },
  // Function: Fetch moodboard items, Type: GETcore/contacts
  fetchMoodboardItems: {
    url: "/moodboards/groupitems/",
    method: "GET",
  },
  // Function: add moodboard items, Type: POST
  addMoodboardGroup: {
    url: "/moodboards/group/",
    method: "POST",
  },
  // Function: update moodboard items, Type: PUT
  updateMoodboardGroup: {
    url: "/moodboards/group/?group_id={id}",
    method: "PUT",
  },

  // Function: update project moodboard, Type: POST
  updateProjectMoodboardItems: {
    url: "/moodboards/projectboarditems/?project_board_id={id}",
    method: "POST",
  },
  // Function: update project moodboard, Type: PUT
  updateProjectMoodboardItem: {
    url: "/moodboards/projectboarditems/?project_board_item_id={id}",
    method: "PUT",
  },

  // Function: add moodboard item, Type: POST
  addMoodboardItem: {
    url: "/moodboards/items/",
    method: "POST",
  },
  // Function: add multiple moodboard item, Type: POST
  addMultipleMoodboardItem: {
    url: "/moodboards/multi_items/",
    method: "POST",
  },
  // Function: update moodboard item, Type: PUT
  updateMoodboardItem: {
    url: "/moodboards/items/?moodboard_item_id={id}",
    method: "PUT",
  },

  //Activity APIs
  // Function: Fetch project activity items, Type: GET
  fetchProjectActivity: {
    url: "/activity/projectactivity/?project_id={id}",
    method: "GET",
  },
  // Function: update project activity , Type: PUT
  updateActivity: {
    url: "/activity/projectactivity/?project_activity_id={id}",
    method: "PUT",
  },
  // Function: add activity to project activity , Type: POST
  addActivitiesToProject: {
    url: "/activity/projectactivity/?fk_project={id}",
    method: "POST",
  },
  // Function: update project activity status , Type: POST
  updatedActivityStatus: {
    url: "/activity/projectactivitystatus/?fk_project_activity={id}",
    method: "POST",
  },
  // Function: update project activity status , Type: POST
  addAcitivityAssetts: {
    url: "/activity/projectactivitystatusassets/",
    method: "POST",
  },

  // Function: Fetch activity tags, Type: GET
  fetchActivityTags: {
    url: "/activity/activitytag/",
    method: "GET",
  },
  // Function: add activity tags, Type: poost
  addActivityTag: {
    url: "/activity/activitytag/",
    method: "POST",
  },
  // Function: update activity tag, Type: PUT
  updateActivityTag: {
    url: "/activity/activitytag/?activity_tag_id={id}",
    method: "PUT",
  },

  // Function: Fetch activity master, Type: GET
  fetchActivityMaster: {
    url: "/activity/activityitem/",
    method: "GET",
  },

  // Function: add activity master, Type: POST
  addActivityMaster: {
    url: "/activity/activityitem/",
    method: "POST",
  },

  // Function: edit activity master, Type: PUT
  editActivityMaster: {
    url: "/activity/activityitem/?activity_item_id={id}",
    method: "PUT",
  },
  // CRM APIs

  // Function: Fetch tasks, Type: GET
  fetchTasks: {
    url: "/crm/tasks/?organization_contact_id={id}",
    method: "GET",
  },
  // Function: Fetch project tasks, Type: GET
  fetchProjectTasks: {
    url: "/crm/tasks/?project_id={id}",
    method: "GET",
  },
  // Function: Create task, Type: POST
  createTask: {
    url: "/crm/tasks/?project_id={id}",
    method: "POST",
  },

  // Function: addAssetsToTask, Type: POST
  addAssetsToTask: {
    url: "/crm/multitaskassets/?task_id={id}",
    method: "POST",
  },

  // Function: Update task, Type: PUT
  updateTask: {
    url: "/crm/tasks/?task_id={id}",
    method: "PUT",
  },
  // Function: delete task asset, Type: PUT
  deleteTaskAsset: {
    url: "/crm/taskassets/?task_asset_id={id}",
    method: "PUT",
  },
  // Function: Fetch notes, Type: GET
  fetchNotes: {
    url: "/crm/notes/?project_id={id}",
    method: "GET",
  },
  // Function: Create note, Type: POST
  createNote: {
    url: "/crm/notes/?project_id={id}",
    method: "POST",
  },
  // Function: Add multi assets to note, Type: POST
  addMultiNoteAssets: {
    url: "/crm/multinoteassets/?note_id={id}",
    method: "POST",
  },
  // Function: Update note, Type: PUT
  updateNote: {
    url: "/crm/notes/?note_id={id}",
    method: "PUT",
  },
  // Function: Delete note asset, Type: PUT
  deleteNoteAsset: {
    url: "/crm/noteassets/?note_asset_id={id}",
    method: "PUT",
  },

  // Order APIs
  // Function: Fetch orders for projects, Type: GET
  fetchVendorOrders: {
    url: "/orders/vendororders/?project_id={id}",
    method: "GET",
  },
  // Function: update vendor orders for projects, Type: POST
  updateOrder: {
    url: "/orders/vendororderitems/?order_id={id}",
    method: "POST",
  },
  // Function: Add orders for projects, Type: POST
  createVendorOrder: {
    url: "/orders/vendororders/?project_id={id}",
    method: "POST",
  },
  // Function: Update orders for projects, Type: PUT
  updateVendorOrder: {
    url: "/orders/vendororders/?vendor_order_id={id}",
    method: "PUT",
  },
  // Function: Fetch quotes, Type: GET
  fetchQuotes: {
    url: "/orders/quotes/?project_id={id}",
    method: "GET",
  },
  // Function: Create quote, Type: POST
  createQuote: {
    url: "/orders/quotes/?project_id={id}",
    method: "POST",
  },
  // Function: Update quotes, Type: PUT
  updateQuotes: {
    url: "/orders/quotes/?quote_id={id}",
    method: "PUT",
  },

  // Function: Update quotes, Type: PUT
  updateQuoteItems: {
    url: "/orders/quoteitems/?quote_id={id}",
    method: "POST",
  },
  // Function: Fetch items, Type: GET
  fetchItems: { url: "/orders/items/", method: "GET" },
  // Function: Update items, Type: PUT
  updateItem: {
    url: "/orders/items/?item_id={id}",
    method: "PUT",
  },
  // Function: add items, Type: POST
  addItem: {
    url: "/orders/items/",
    method: "POST",
  },

  // Function: Get Project Financials items, Type: PUT
  fetchFinancials: {
    url: "/financials/payments/?project_id={id}",
    method: "GET",
  },
  // Function: add payment item, Type: POST
  addPayment: {
    url: "/financials/payments/?project_id={id}",
    method: "POST",
  },

  // Asset APIs
  // Function: Post asset, Type: POST
  coreAssets: { url: "/core/assets/", method: "POST" },
  // Function: Update assets, Type: PUT
  updateAsset: {
    url: "/core/assets/?asset_id={id}",
    method: "PUT",
  },

  // Function:search pan card, Type: get
  panSearch: {
    url: "/core/pansearch/?pan={id}",
    method: "GET",
  },
  // Function:address api
  coreAddress: {
    url: "/core/address/",
    method: "POST",
  },
  // Function:contacts api
  coreContacts: {
    url: "/core/contacts/",
    method: "POST",
  },
  // Function:contacts api
  updateCoreContact: {
    url: "/core/contacts/?contact_id={id}",
    method: "PUT",
  },
  // Function:presignedUrl api
  corePresignedUrls: {
    url: "/core/presignedurls/",
    method: "POST",
  },

  // Function:project multiple assets api
  addProjectMultipleAssets: {
    url: "/customers/multiprojectassets/",
    method: "POST",
  },

  // Function:add asset group api
  addAssetGroup: {
    url: "/customers/projectassetgroup/?project_id={id}",
    method: "POST",
  },
  // Function:update asset group api
  updateAssetGroup: {
    url: "/customers/projectassetgroup/?project_asset_group_id={id}",
    method: "PUT",
  },
  // Function:add user to project api Type: POST
  addUserToProject: {
    url: "/customers/projectuser/?project_id={id}",
    method: "POST",
  },
  // Function:remove user to project api Type: PUT
  removeUserFromProject: {
    url: "/customers/projectuser/?project_id={id}",
    method: "PUT",
  },

  // Function: Add assets to quote Type: POST
  addAssetToQuote: {
    url: "/orders/quoteasset/?quote_id={id}",
    method: "POST",
  },
  // Function: Update quote assets  Type: PUT
  updateAssetToQuote: {
    url: "/orders/quoteasset/?quote_asset_id={id}",
    method: "PUT",
  },
  // Function: Add assets to order Type: POST
  addAssetToOrder: {
    url: "/orders/vendororderasset/?vendor_order_id={id}",
    method: "POST",
  },
  // Function: Update assets to order Type: PUT
  updateAssetToOrder: {
    url: "/orders/vendororderasset/?vendor_order_asset_id={id}",
    method: "PUT",
  },
  // Function: Update user password: POST
  resetPassword: {
    url: "/auth/resetpassword/",
    method: "POST",
  },

  // Function: add user : POST
  addUser: {
    url: "/auth/usercreate/",
    method: "POST",
  },

  // Function: Update user : PUT
  updateUser: {
    url: "/auth/usercreate/?organization_contact_id={id}",
    method: "PUT",
  },
  // Function: delete user : DELETE
  deleteUser: {
    url: "/auth/usercreate/?organization_contact_id={id}",
    method: "DELETE",
  },

  // Function: get payment terms: GET
  getPaymentTerms: {
    url: "/orders/paymentterms/",
    method: "GET",
  },

  // Function: get sections: GET
  getQuoteSections: {
    url: "/orders/quoteitemsection/",
    method: "GET",
  },
  // Function: add section: POST
  addQuoteSection: {
    url: "/orders/quoteitemsection/",
    method: "POST",
  },
  // Function: GET all the additional charges of that organization
  getAdditionalChargesList: {
    url: "/orders/additionalcharges/",
    method: "GET",
  },

  // Function: add the additional charges to quote : POST
  addAdditionalChargesToQuote: {
    url: "/orders/additionalcharges/items/?context_id={id}",
    method: "POST",
  },
  // Function: add the additional charges to  order: POST
  addAdditionalChargesToOrder: {
    url: "/orders/additionalcharges/items/?context_id={id}",
    method: "POST",
  },

  // Function: get payment types: GET
  getPaymentTypes: {
    url: "/financials/paymenttypes/",
    method: "GET",
  },
  // Function: get transaction types: GET
  getTransactionTypes: {
    url: "/financials/transactiontypes/",
    method: "GET",
  },

  // Function: add comment to context : POST
  addComment: {
    url: "/core/comments/?context_id={id}&context_type={context}",
    method: "POST",
  },
  // Function: get comments of context : GET
  getComments: {
    url: "/core/comments/?context_id={id}&context_type={context}",
    method: "GET",
  },
  // Function: Duplicate quote : POST
  duplicateQuote: {
    url: "/orders/duplicatequotes/?project_id={id}",
    method: "POST",
  },
  // Function: Get logs : GET
  getLogs: {
    url: "/core/logs/?page={page}&page_size={pageSize}",
    method: "POST",
  },
  // Function: get tasks by pagination : GET
  getTasksByPage: {
    url: "/crm/tasks/?organization_contact_id={id}&page={page}&page_size={pageSize}",
    method: "POST",
  },
  // Function: delete quote : DELETE
  deleteQuote: {
    url: "/orders/quotes/?quote_id={id}",
    method: "DELETE",
  },
  // Function: delete order : DELETE
  deleteOrder: {
    url: "/orders/vendororders/?vendor_order_id={id}",
    method: "DELETE",
  },

  // Function: delete activity : DELETE
  deleteActivity: {
    url: "/activity/projectactivity/?project_activity_id={id}",
    method: "DELETE",
  },
  // Function: delete payment from financial : DELETE
  deletePayment: {
    url: "/financials/payments/?payment_id={id}",
    method: "DELETE",
  },
  // Function: is used to update payment details  Type: PUT
  updatePayment: {
    url: "/financials/payments/?payment_id={id}",
    method: "PUT",
  },
  // Function: get all rfqs : POST
  createNewRFQ: {
    url: "/rfq/rfq/",
    method: "POST",
  },

  // Function: get all rfqs : GET
  getAllRFQs: {
    url: "/rfq/rfq/",
    method: "GET",
  },
  // Function: update rfq : PUT
  updateRFQ: {
    url: "/rfq/rfq/?rfq_id={id}",
    method: "PUT",
  },

  // Function: get all rfq items : GET
  getAllRFQItems: {
    url: "/rfq/rfqitems/?rfq_id={id}",
    method: "GET",
  },
  // Function: add rfq items : POST
  addRFQItems: {
    url: "/rfq/rfqitems/?rfq_id={id}",
    method: "POST",
  },
  // Function: update rfq items : PUT
  updateRFQItem: {
    url: "/rfq/rfqitems/?rfq_item_id={id}",
    method: "PUT",
  },

  // Function: get all rfq item assets : GET
  getAllRFQItemAssets: {
    url: "/rfq/rfqitemassets/?rfq_item_id={id}",
    method: "GET",
  },
  // Function: add rfq item assets : POST
  addRFQItemAssets: {
    url: "/rfq/rfqitemassets/?rfq_item_id={id}",
    method: "POST",
  },
  // Function: update rfq item assets : PUT
  updateRFQItemAssets: {
    url: "/rfq/rfqitemassets/?rfq_item_id={id}",
    method: "PUT",
  },

  // Function: get all quotes received : GET
  getAllQuotesReceived: {
    url: "/rfq/quotereceived/?rfq_id={id}",
    method: "GET",
  },
  // Function: add quotes received : POST
  addQuotesReceived: {
    url: "/rfq/quotereceived/?rfq_id={id}",
    method: "POST",
  },
  // Function: update quotes received : PUT
  updateQuotesReceived: {
    url: "/rfq/quotereceived/?rfq_quote_id={id}",
    method: "PUT",
  }, // Function: get all client progress by project id : GET
  fetchClientProgressActivities: {
    url: "/clientprogress/projectclientprogress/?project_id={id}",
    method: "GET",
  },
  // Function: add client progress : POST
  addClientProgress: {
    url: "/clientprogress/projectclientprogress/?fk_project={id}",
    method: "POST",
  },
  // Function: update client progress : PUT
  updateClientProgress: {
    url: "/clientprogress/projectclientprogress/?project_client_progress_id={id}",
    method: "PUT",
  },
  // Function: add client progress status : POST
  addClientProgressStatus: {
    url: "/clientprogress/projectclientprogressstatus/?fk_project_client_progress={id}",
    method: "POST",
  },
  // Function: add client progress status assets : POST
  addClientProgressStatusAssets: {
    url: "/clientprogress/projectclientprogressstatusassets/",
    method: "POST",
  },
  // Function: update client progress status assets : PUT
  updateClientProgressStatusAssets: {
    url: "/clientprogress/projectclientprogressstatusassets/",
    method: "PUT",
  },
  // Function: get brands : GET
  getBrands: {
    url: "/core/brands/",
    method: "GET",
  },

  // Function: add multiple activity owners, Type: PUT
  addMultipleActivityOwners: {
    url: "/activity/multipleactivityowner/",
    method: "PUT",
  },

  // Chat APIs
  // Function: Create a chat group, Type: POST
  createChatGroup: {
    url: "/chat/groups/?organization_contact_id={id}",
    method: "POST",
  },
  // Function: Get all chat groups for an organization contact, Type: GET
  getChatGroups: {
    url: "/chat/groups/?organization_contact_id={id}",
    method: "GET",
  },
  // Function: Edit chat group members, Type: PUT
  editChatGroupMembers: {
    url: "/chat/groups/?group_id={id}",
    method: "PUT",
  },
  // Function: Get all messages of a chat group, Type: GET
  getChatGroupMessages: {
    url: "/chat/messages/?group_id={id}",
    method: "GET",
  },
  //Materials related APIs
  // Function: Create purchase request, Type: POST
  createPurchaseRequest: {
    url: "/materials/purchaserequest/?project_id={id}",
    method: "POST",
  },
  // Function: Get purchase request, Type: GET
  getPurchaseRequest: {
    url: "/materials/purchaserequest/?project_id={id}",
    method: "GET",
  },
  // Function: Update purchase request, Type: PUT
  updatePurchaseRequest: {
    url: "/materials/purchaserequest/?pr_id={id}",
    method: "PUT",
  },
  // Function: Create purchase request items, Type: POST
  createPurchaseRequestItems: {
    url: "/materials/pritems/?fk_prid={id}",
    method: "POST",
  },
  // Function: Get purchase request items, Type: GET
  getPurchaseRequestItems: {
    url: "/materials/pritems/?fk_prid={id}",
    method: "GET",
  },
  // Function: Update purchase request items, Type: PUT
  updatePurchaseRequestItems: {
    url: "/materials/pritems/?fk_prid={id}",
    method: "PUT",
  },
  // Function: Create GRN, Type: POST
  createGRN: {
    url: "/materials/grn/?fk_prid={id}",
    method: "POST",
  },
  // Function: Get GRN, Type: GET
  getGRN: {
    url: "/materials/grn/?fk_prid={id}",
    method: "GET",
  },
  // Function: Update GRN, Type: PUT
  updateGRN: {
    url: "/materials/grn/?grn_id={id}",
    method: "PUT",
  },
  // Function: Create GRN assets, Type: POST
  createGRNAssets: {
    url: "/materials/grnassets/?fk_grn={id}",
    method: "POST",
  },
  // Function: Create GRN items, Type: POST
  createGRNItems: {
    url: "/materials/grnitems/?fk_grn={id}",
    method: "POST",
  },
  // Function: Get GRN items, Type: GET
  getGRNItems: {
    url: "/materials/grnitems/?fk_grn={id}",
    method: "GET",
  },
  // Function: Update GRN items, Type: PUT
  updateGRNItems: {
    url: "/materials/grnitems/?grn_item_id={id}",
    method: "PUT",
  },
  // Function: Create consumption, Type: POST
  createConsumption: {
    url: "/materials/consumption/?project_id={id}",
    method: "POST",
  },
  // Function: Get consumption, Type: GET
  getConsumption: {
    url: "/materials/consumption/?project_id={id}",
    method: "GET",
  },
  // Function: Create consumption assets, Type: POST
  createConsumptionAssets: {
    url: "/materials/consumptionassets/?fk_consumption={id}",
    method: "POST",
  },
  // Function: Get inventory items, Type: GET
  getInventoryItems: {
    url: "/materials/inventoryitems/",
    method: "GET",
  },
};

import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { arrayFrom, stringFrom } from "../parsing";
export function formatClientsData(client) {
  return {
    id: stringFrom(client.id),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(client.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(client.created_on)),
    clientName: stringFrom(client.contact_details.name),
    phone: stringFrom(client.contact_details.phone),
    email: stringFrom(client.contact_details.email),
    addressId: stringFrom(client.address_details.id),
    contactId: stringFrom(client.contact_details.id),
    addressLine1: stringFrom(client.address_details.address_line_1),
    addressLine2: stringFrom(client.address_details.address_line_2),
    pincode: stringFrom(client.address_details.pincode),
    location: stringFrom(client.address_details.location),
    legalName: stringFrom(client.legal_name),
    gstNumber: stringFrom(client.gst_number),
    city: stringFrom(client.address_details.city),
    state: stringFrom(client.address_details.state),
    remarks: stringFrom(client.remarks),
    contacts: arrayFrom(
      client.additional_contacts.map((item) => {
        const { id, name, email, phone } = item;
        return {
          id,
          name,
          email,
          phone,
          fkContact: item.fk_contact,
          isAlreadyPresent: 1,
        };
      })
    ),
  };
}

export function formatClientProject(project) {
  return {
    budget: stringFrom(project.budget),
    description: stringFrom(project.id),
    id: stringFrom(project.id),
    name: stringFrom(project.project_name),
    stage: stringFrom(project.project_stage),
  };
}

import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { boolFrom, stringFrom } from "../parsing";

export function formatManpowerItems(item) {
  return {
    id: stringFrom(item.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    name: stringFrom(item.name),
    isActive: boolFrom(item.is_active),
  };
}

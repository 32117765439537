<template>
  <v-app-bar app height="60" elevation="2">
    <v-img
      class="ml-1"
      max-height="55"
      max-width="150"
      :src="
        logo ||
        'https://www.projectstudio.ai/wp-content/uploads/2024/09/logo.png'
      "
      alt="Project Studio"
    />

    <v-spacer></v-spacer>
    <appBarMenu> </appBarMenu>
  </v-app-bar>
</template>

<script>
import appBarMenu from "./appBarMenu.vue";

export default {
  name: "AppBar",
  components: { appBarMenu },
  data() {
    return { logo: "" };
  },
  created() {
    this.logo =
      this.$store.getters.currentUser.organization_details.asset_details.url;
  },
};
</script>
